<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Recherche</b-card-title>
              </b-card-header>

              <b-card-body>
                <b-form @submit.prevent="reloadIframe">
                  <b-form-group>
                    <b-form-input
                        type="text"
                        v-model="logID"
                        placeholder="Veuillez préciser l'id de la transaction"
                    ></b-form-input>
                  </b-form-group>
                  <b-button
                      class=""
                      type="submit"
                      :disabled="isLoading"
                  ><span v-if="!isLoading">Rechercher</span><span v-if="isLoading">Chargement...</span></b-button>
                </b-form>
              </b-card-body>

              
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <b-row class="match-height">
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Scanner</b-card-title>
              </b-card-header>

              <b-card-body style="height: 100vh;">
                <iframe src="/scanner.html" frameborder="0" width="100%" height="90%" @load="fetchResource" ref="iframeScanner"></iframe>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from "axios";
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";

export default {

  components: {
    BButton, BFormGroup, BForm, BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  data() {
    return {
      file: null,
      privateKey: "",
      pubKey: "",
      resource: "",
      type: "",
      logID: "",
      logLoad: true,
      contentB64: "",
      isLoading: false
    }
  },
  mounted() {
    this.publicKey = localStorage.getItem('publicKey') || '';
    this.privateKey = localStorage.getItem('privateKey') || '';

    if (this.$route.params.log) {
      this.logID = Number(this.$route.params.log);
      setTimeout(() => {
        this.reloadIframe();
      }, 2000);
    }

  },
  methods: {
    reloadIframe() {
      const iframe = this.$refs.iframeScanner;
      iframe.contentWindow.location.reload();
    },
    fetchResource() {
      if (this.logID === "") return;
      axios.get("https://apisb.openlogs.fr/api/user/resource/" + this.logID).then(response => {
        if (response.data.data.pathResource)
          this.resource = response.data.data.pathResource;
        if (response.data.data.resourceType)
          this.type = response.data.data.resourceType;
        const iframeWindow = this.$refs.iframeScanner.contentWindow;
        iframeWindow.postMessage({
          type: this.type,
          content: this.resource
        }, '*');
      }).catch((err) => {
        alert("Erreur, l'id que vous avez précisez n'existe pas !");
      })
    },
    onSubmit() {
      this.isLoading = true;
      axios.post("https://apisb.openlogs.fr/api/user/resource", {
        content: this.contentB64,
        type: this.type
      }, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(response => {
        if (response.data.data.pathResource)
          this.resource = response.data.data.pathResource;
        if (response.data.data.resourceType)
          this.type = response.data.data.resourceType;
        this.isLoading = false;
        const iframeWindow = this.$refs.iframeScanner.contentWindow;
        iframeWindow.postMessage({
          type: this.type,
          content: this.resource
        }, '*');
      }).catch(() => {
        this.isLoading = false;
      })
    },
    onFileChange(file) {
      if (file ) {
        let tmpType = "";
        if (file.type.startsWith('image/')) tmpType = "image";
        if (file.type.startsWith('video/mp4')) tmpType = "video";
        if (tmpType === "") return;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.type = tmpType;
          this.contentB64 = e.target.result.split(",")[1];
        };
        reader.readAsDataURL(file);
      }
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.transaction-item .media {
  align-items: center;
}
</style>
